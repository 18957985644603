import {
  extractInstruction,
  reduceVariableInstructions,
  type ApiInstruction,
  type Instruction,
} from '@backstage/instructions';
import {type MutableRefObject} from 'react';
import {EMPTY, Observable, map, type Subject} from 'rxjs';
import {
  assign,
  fromEventObservable,
  fromPromise,
  raise,
  setup,
  type DoneActorEvent,
  type ErrorActorEvent,
} from 'xstate';
import {type BroadcastFunction} from './transformations/node.types';
import type {FetchInstructionsFn, ShowFetchInstructionsFn} from './types';

type TransitionEvent<
  Kind extends string,
  Data extends Record<string, unknown> = Record<string, never>,
> = Data extends Record<string, never> ? {type: Kind} : {type: Kind} & Data;

interface InstructionBaseContext {
  /** Ref for function used when retrieving instructions */
  getInstructions: MutableRefObject<ShowFetchInstructionsFn>;
  /** Subject into which newly retrieved instructions are pushed */
  subject: Subject<Instruction[] | Error>;
  /** Function called push instructions into an analytics batch */
  track: BroadcastFunction;
}

interface InstructionTypestateContext {
  observable?: Observable<ApiInstruction[]>;
  sinceInstructionId?: string;
  error?: Error;
  lastInstructions?: ApiInstruction[];
}

type InstructionContext = InstructionBaseContext & InstructionTypestateContext;

type Action =
  | TransitionEvent<'INIT'>
  | TransitionEvent<'FETCH'>
  | TransitionEvent<'AWAIT_MORE'>
  | TransitionEvent<'RECEIVE', {instructions: ApiInstruction[]}>
  | TransitionEvent<'REQUEST_ERROR', {error: Error}>
  | TransitionEvent<
      'REQUEST_COMPLETE',
      {instructions: ApiInstruction[]; latestInstructionId?: string}
    >
  | TransitionEvent<
      'SUBSCRIBE',
      {observable: Observable<ApiInstruction[]>; latestInstructionId?: string}
    >;

type FetchInstructionsResponse = Awaited<ReturnType<FetchInstructionsFn>>;

/** Promise creator called when transitioning into the `PENDING` state */
const pendingInvoke = (
  context: InstructionContext
): Promise<FetchInstructionsResponse> => {
  const getInstructions = context.getInstructions.current;
  if (typeof getInstructions === 'function') {
    return getInstructions(context.sinceInstructionId);
  } else {
    return Promise.resolve({});
  }
};

/** Actions to perform when `pendingInvoke` resolves */
const onDoneActions = [
  raise<
    InstructionContext,
    DoneActorEvent<FetchInstructionsResponse>,
    Action,
    undefined
  >(({event}) => {
    const result = event.output;
    if ('observable' in result) {
      return {
        type: 'SUBSCRIBE',
        observable: result.observable,
        latestInstructionId: result.latestInstructionId ?? undefined,
      };
    } else if (
      typeof result.data?.showById !== 'undefined' &&
      result.data?.showById !== null
    ) {
      return {
        type: 'REQUEST_COMPLETE',
        instructions: result.data.showById.showInstructions,
        latestInstructionId:
          result.data.showById.latestInstructionId ?? undefined,
      };
    } else if (typeof result.error !== 'undefined') {
      return {type: 'REQUEST_ERROR', error: result.error};
    } else {
      return {type: 'REQUEST_COMPLETE', instructions: []};
    }
  }),
];

/** Actions to perform when `pendingInvoke` rejects */
const onErrorActions = [
  raise<InstructionContext, ErrorActorEvent<unknown>, Action, undefined>(
    ({event}) => {
      const reason: unknown = event.error;
      return {
        type: 'REQUEST_ERROR',
        error: reason instanceof Error ? reason : new Error(),
      };
    }
  ),
];

/**
 * A state machine which manages the retrieval of new instructions. Retrieved
 * instructions are passed into the given `Subject` when returned by the given
 * `getInstructions` function. `getInstructions` is invoked when the state
 * machine enters the `PENDING` state.
 */
export const showInstructionsMachine = setup({
  types: {} as {
    context: InstructionContext;
    events: Action;
    input: InstructionBaseContext;
  },
  actors: {
    fetchInstructions: fromPromise<
      FetchInstructionsResponse,
      InstructionContext
    >(async ({input}) => pendingInvoke(input)),
    listenInstructions: fromEventObservable<
      Action,
      Observable<ApiInstruction[]>
    >(({input}) =>
      input.pipe(map((instructions) => ({type: 'RECEIVE', instructions})))
    ),
  },
  actions: {
    pushInstructions: ({context}) => {
      if (Array.isArray(context.lastInstructions)) {
        applyInstructions(context, context.lastInstructions);
      }
    },
  },
}).createMachine({
  predictableActionArguments: true,
  id: 'instructions',
  initial: 'INITIALIZING',
  context: ({input}): InstructionContext => ({
    getInstructions: input.getInstructions,
    subject: input.subject,
    track: input.track,
  }),
  states: {
    INITIALIZING: {
      on: {INIT: {target: 'WAITING'}},
    },
    WAITING: {
      on: {
        FETCH: {
          target: 'PENDING',
        },
      },
    },
    PENDING: {
      invoke: {
        id: 'fetch',
        src: 'fetchInstructions',
        input: ({context}) => context,
        onDone: {actions: onDoneActions},
        onError: {actions: onErrorActions},
      },
      on: {
        REQUEST_ERROR: {
          actions: assign({error: ({event}) => event.error}),
          target: 'ERROR',
        },
        REQUEST_COMPLETE: {
          actions: assign({
            lastInstructions: ({event}) => {
              return event.instructions.slice(0);
            },
            sinceInstructionId: ({context, event}) => {
              const lastInstruction = event.instructions.slice(-1)[0];
              if (event.latestInstructionId) {
                return event.latestInstructionId;
              } else if (typeof lastInstruction !== 'undefined') {
                // This case should be unreachable, but is being left in for safety.
                return lastInstruction.id;
              } else {
                return context.sinceInstructionId;
              }
            },
          }),
          target: 'DONE',
        },
        SUBSCRIBE: {
          actions: assign({
            observable: ({event}) => event.observable,
            sinceInstructionId: ({event}) => event.latestInstructionId,
          }),
          target: 'LISTENING',
        },
      },
    },
    LISTENING: {
      invoke: {
        input: ({event}) =>
          event.type === 'SUBSCRIBE' ? event.observable : EMPTY,
        src: 'listenInstructions',
        // Go to `WAITING` when the observable completes so `getInstructions`
        // is triggered again quickly. Going to `DONE` waits 5 seconds before
        // re-fetching.
        onDone: {target: 'WAITING'},
        onError: {target: 'ERROR'},
      },
      on: {
        RECEIVE: {
          actions: [
            assign({
              lastInstructions: ({event}) => {
                return event.instructions.slice(0);
              },
              sinceInstructionId: ({event}) => {
                const lastInstruction = event.instructions.slice(-1)[0];
                return lastInstruction?.id;
              },
            }),
            ({context, event}) => {
              applyInstructions(context, event.instructions);
            },
          ],
        },
      },
    },
    ERROR: {
      entry: ({context}) => {
        if (context.error instanceof Error) {
          context.subject.next(context.error);
        }
      },
      on: {
        AWAIT_MORE: {
          target: 'WAITING',
        },
      },
      after: {
        5000: {
          actions: raise({type: 'AWAIT_MORE'}),
        },
      },
    },
    DONE: {
      entry: ['pushInstructions'],
      on: {
        AWAIT_MORE: {
          target: 'WAITING',
        },
      },
      after: {
        5000: {
          actions: raise({type: 'AWAIT_MORE'}),
        },
      },
    },
  },
});

/**
 * For each `ApiInstruction` received push it into the `Subject` and track it
 * via the analytics `BroadcastFunction` as long as the `sinceInstructionId` is
 * set indicating this is not the initial payload.
 */
function applyInstructions(
  context: InstructionContext,
  apiInstructions: ApiInstruction[]
): void {
  const {actions, setters} = reduceVariableInstructions(apiInstructions);
  const instructions = setters.concat(actions).map(extractInstruction);
  context.subject.next(instructions);
  // `sinceInstructionId` is always set after the first payload is received,
  // this avoids tracking the initial payload
  if (typeof context.sinceInstructionId !== 'undefined') {
    instructions.forEach((instruction) => context.track(instruction, 'api'));
  }
}
