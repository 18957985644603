// eslint-disable-next-line no-restricted-globals
type Storage = typeof localStorage;

/**
 * @private exported for tests
 */
export class MemoryStorage implements Storage {
  private keys: string[] = [];
  private readonly backing = new Map<string, string>();

  clear(): void {
    this.backing.clear();
  }

  getItem(key: string): string | null {
    return this.backing.get(key) ?? null;
  }

  key(index: number): string | null {
    const item = this.keys.length > index ? this.keys[index] : undefined;
    if (typeof item !== 'undefined') {
      return item;
    } else {
      return null;
    }
  }

  get length(): number {
    return this.backing.size;
  }

  removeItem(key: string): void {
    this.backing.delete(key);
    this.keys = Array.from(this.backing.keys());
  }

  setItem(key: string, value: string): void {
    this.backing.set(key, value);
    this.keys = Array.from(this.backing.keys());
  }
}

const getStorage = (): Storage => {
  try {
    // When called outside of the browser context, localStorage is not defined,
    // so throw an error to use the `catch` instead of duplicating logic.
    // eslint-disable-next-line no-restricted-globals
    if (typeof localStorage === 'undefined') {
      throw new Error('localStorage is not defined');
    }

    // When in incognito mode and served within an iframe, `localStorage` is
    // defined but throws an error when accessed. Use the `catch` to use
    // `MemoryStorage` instead.
    // eslint-disable-next-line no-restricted-globals
    return localStorage;
  } catch (error) {
    return new MemoryStorage();
  }
};

/**
 * Provides an interface like `localStorage`. Uses the window's or navigator's
 * localStorage APIs if they are available otherwise falls back to an in-memory
 * `Map` structure.
 */
export const storage = getStorage();
