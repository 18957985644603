import type {Static, TNever, TObject, TUnion} from '@sinclair/typebox';
import {JSONSchema7} from 'json-schema';
import {InstructionSchema} from '../helpers';
import {AnalyticsInstructionMask} from '../types';
import {JSONObject, JSONValue} from './json';

export interface SlotConfiguration extends JSONObject {
  title: string;
  maxModules: number;
  displayWidth: number;
}

/**
 * Collection of rules defining which modules must be present or must not be present in the module tree.
 * In other words, some modules require certain types of parent modules or have restrictions on
 * the types of children they may have.
 * Notably absent at the time of creating this type: "allowedChildren" or any variant of
 * restricting the type of direct children.
 * For now, it is overly restrictive to allow only specific direct children since someone could
 * conceivably want to use a small layout module or possibly a context module. For a case
 * like a slideshow, it's expected that instead of insisting on only a `slide`, the component
 * author would instead wrap each slot module in a `slide` internal component.
 */
type PositionRestrictions = {
  /**
   * Module types (comma-delimited and/or different elements) that are necessary as desendants
   * of this selector. Generally, the layout editor makes no dropping restrictions based on
   * this. This rule is able to be linted in order to identify required yet still missing.
   */
  necessaryDescendants?: string[];
  /**
   * Module types (comma-delimited and/or different elements) that are necessary as ancestors
   * of this selector. Generally, the layout editor should disallow dropping a module anywhere
   * on the page where that didn't satisfy this restriction.
   */
  necessaryAncestors?: string[];
  /**
   * Module types (comma-delimited and/or different elements) that are not allowed as
   * desendants of this Module. Adding this component's ReactName to the list will prevent self-nesting.
   */
  disallowedDescendants?: string[];
  /**
   * Module types (comma-delimited and/or different elements) that are not allowed as
   * ancestors of this Module. Adding this component's ReactName to the list will prevent self-nesting.
   */
  disallowedAncestors?: string[];
};

/**
 * NOTE: this list is being maintained manually here, as well as in `_templates/component/new/prompt.js`
 */
export const moduleCategories = [
  'unclassified',
  'layout',
  'form',
  'typography',
  'component',
  'preset',
  'rtc',
  'media',
  'custom',
  'internal',
  'plugin',
] as const;

export type ModuleCategory = (typeof moduleCategories)[number];

export interface ComponentAdmin<
  Instructions extends TUnion = TUnion<InstructionSchema[]>,
> {
  id: string;
  name: string;
  description: string;
  version: number;
  defaultFieldData: JSONValue;
  slotConfiguration: Record<string, SlotConfiguration>;
  slug: string;
  reactName: string;
  schema?: JSONSchema7;
  uiSchema?: JSONObject;
  instructions?: Instructions;
  positionRestrictions?: PositionRestrictions;
  /**
   * JSON Schema defining private field data. Private field data will not be
   * available during preview or guest rendering but will be available in the
   * API.
   */
  privateSchema?: JSONSchema7;
  category: ModuleCategory;
  /**
   * This function will be called for each instruction passed through the local
   * broker before it is sent to the analytics endpoint. It is up to the module
   * author to mask or remove information from an instruction which should be
   * hidden. The _structure_ of the masked instruction must be the same meaning
   * the `type` must be identical and the keys of the `meta` must be a subset
   * of the original.
   * @default - an identity function (returns the input value as is)
   */
  analyticsInstructionMask?: AnalyticsInstructionMask<Instructions>;
}

/** Strongly typed extension of `ComponentAdmin` using TypeBox schemas */
export interface TypedComponentAdmin<
  Schema extends TObject,
  Instructions extends TUnion | TNever,
  DefaultFieldData extends Static<Schema> = Static<Schema>,
  PrivateSchema extends TObject = TObject,
> {
  id: string;
  name: string;
  description: string;
  version: number;
  defaultFieldData: DefaultFieldData;
  slotConfiguration: Record<string, SlotConfiguration>;
  slug: string;
  reactName: string;
  schema: Schema;
  uiSchema?: JSONObject;
  instructions: Instructions;
  positionRestrictions?: PositionRestrictions;
  /**
   * JSON Schema defining private field data. Private field data will not be
   * available during preview or guest rendering but will be available in the
   * API.
   */
  privateSchema?: PrivateSchema;
  category: ModuleCategory;
  /**
   * This function will be called for each instruction passed through the local
   * broker before it is sent to the analytics endpoint. It is up to the module
   * author to mask or remove information from an instruction which should be
   * hidden. The _structure_ of the masked instruction must be the same meaning
   * the `type` must be identical and the keys of the `meta` must be a subset
   * of the original.
   * @default - an identity function (returns the input value as is)
   */
  analyticsInstructionMask?: AnalyticsInstructionMask<Instructions>;
}
